import { BaseFile } from "./BaseFile";

export class ExportedTRDFile extends BaseFile
{    
    reportOption    

    salechannels
    devices
    
    dates

    constructor(params) {
        super(params)

        const { salechannels, devices } = params

        this.salechannels = salechannels
        this.devices = devices
    }

    serailize() {
        const startDate = Array.isArray(this.dates) ? this.dates[0] : this.date
        const endDate = Array.isArray(this.dates) ? this.dates[1] : this.date

        let salechannel_ids
        let device_ids

        if (this.reportOption == 'both' || this.reportOption == 'online') {
            if (!!this.salechannels) salechannel_ids = this.salechannels.map(item => item.id)
          } else {
            salechannel_ids = []
          }
    
          if (this.reportOption == 'both' || this.reportOption == 'pos') {
            if (!!this.devices) device_ids = this.devices.map(item => item.id)
          } else {
            device_ids = []
          }

        console.log(salechannel_ids)

        return {
            salechannel_ids: salechannel_ids,
            device_ids: device_ids,
            start: !!startDate ? startDate : null,
            end: !!endDate ? endDate : null
        }
    }
}