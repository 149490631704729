<template>
    <div>
        <h2><router-link :to="{ name: 'trd' }">ใบกำกับภาษีแบบเต็ม</router-link> > ส่งออกข้อมูลใบกำกับภาษีแบบเต็ม</h2>
        <v-card class="mt-4">
          <v-card-title>ส่งออกไฟล์</v-card-title>
          
          <v-card-subtitle>
            เนื่องจากการส่งออกไฟล์ใบกำกับภาษีแบบเต็ม จะมีการประมวลผลข้อมูลที่ใช้ระยะเวลาประมาณหนึ่ง เมื่อประมวลผลเสร็จแล้ว ไฟล์จะพร้อมให้ดาวน์โหลดในหน้านี้ ตรง "ประวัติการส่งออกไฟล์"

            ท่านสามารถส่งออกไฟล์ได้โดยการกดปุ่มด้านล่าง และโปรดเข้ามาตรวจสอบในหน้านี้อีกครั้งในภายหลัง

            ทั้งนี้ ไฟล์จะมีอายุ 2 สัปดาห์ นับจากวันที่ได้รับไฟล์
          </v-card-subtitle>

          <div>
            <v-container>
              <v-row>
                <v-col class="d-flex">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="ml-auto"
                        >
                          <v-icon class="mr-2">{{ icons.mdiCalendar }}</v-icon> {{ displayDate }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                        v-for="(item, index) in dateList"
                        :key="index"
                        @click="setDate(item.id)"
                        >
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item                    
                        @click="callCalendarModal"
                        >
                            <v-list-item-title>กำหนดเอง</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="reportOption"
                    row
                  >
                    <v-radio
                      label="ดูยอดของทั้งออนไลน์และ POS"
                      value="both"
                    ></v-radio>
                    <v-radio
                      label="ดูเฉพาะยอดออนไลน์"
                      value="online"
                    ></v-radio>
                    <v-radio
                      label="ดูเฉพาะยอด POS"
                      value="pos"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
              <v-col cols="12" md="6">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="file.salechannels"             
                  label="ช่องทางการขายออนไลน์ที่ต้องการดู"
                  placeholder="เว้นว่างถ้าต้องการดูทุกช่องทางการขายออนไลน์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/channelList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="saleChannelAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                  :disabled="reportOption == 'pos'"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="file.devices"             
                  label="เครื่อง POS ที่ต้องการดู"
                  placeholder="เว้นว่างถ้าต้องการดูทุกเครื่อง POS"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/deviceList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="posDeviceAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                  :disabled="reportOption == 'online'"
                ></delay-autocomplete>
              </v-col>
            </v-row>
            </v-container>            
          </div>

          <v-card-actions>              
              <v-btn
                color="primary"               
                large
                :loading="sendingData"
                @click="submitForm"
              >
                ส่งออก
              </v-btn>
              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>              
            </v-card-actions>
        </v-card>

        <v-card class="mt-4">
            <v-card-title>ประวัติการส่งออกไฟล์สินค้า</v-card-title>

            <div>
                <v-simple-table style="width: 100%;">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สร้างเมื่อ
                            </th>
                            <th class="text-left">
                                อัพเดธล่าสุด
                            </th>
                            <th class="text-left">
                                ไฟล์
                            </th>
                            <th class="text-left">
                                สถานะ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in list"
                            :key="item.id"
                        >
                            <td>{{ item.createdAt }}</td>
                            <td>{{ item.updatedAt }}</td>
                            <td>
                                <template v-if="item.status == 'failed'">
                                    {{ item.message }}
                                </template>
                                <template v-else-if="item.status == 'success'">
                                    <a :href="item.URL">{{ item.name }}</a>
                                </template>
                                <template v-else>
                                    กำลังประมวลผล
                                </template>          
                            </td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>            
        </v-card>

        <v-dialog
          ref="dialog"
          v-model="calendarModal"        
          :return-value.sync="file.dates"  
          persistent
          width="290px"
        >
          <v-date-picker
            v-model="file.dates"
            scrollable
            range
            :max="maxDate"
            :min="minDate"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              @click="calendarModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(file.dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
    </div>
</template>
<script>
import { ExportedTRDFile } from '@/classes/file/ExportedTRDFile'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { dateArray } from '@/helpers/dateTimeConverter'
import errorParser from '@/plugins/errorparser'
import { mdiCalendar } from '@mdi/js'
export default {
  data() {
    return {
      list: [],
      file: null,
      sendingData: false,
      dateArray: dateArray,
      minDate: null,
      maxDate: null,
      calendarModal: false,
      reportOption: 'both',
      icons: {
        mdiCalendar,
      },
      dateList: [
        {
          title: 'วันนี้',
          id: 'today',
        },
        {
          title: 'เมื่อวาน',
          id: 'yesterday',
        },
        {
          title: '7 วันที่แล้ว',
          id: 'last7',
        },
        {
          title: '30 วันที่แล้ว',
          id: 'last30',
        },
        {
          title: 'เดือนนี้',
          id: 'month',
        },
        {
          title: 'เดือนที่แล้ว',
          id: 'lastmonth',
        },
      ],
    }
  },
  async created() {
    const today = new Date()
    const minDate = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate())

    this.maxDate = this.dateToDBFormat(today)
    this.minDate = this.dateToDBFormat(minDate)

    if (!this.$store.getters['auth/GET_ABILITY'].can('update', 'Product')) {
      return this.$router.go(-1)
    }

    this.file = new ExportedTRDFile({})

    this.$store.commit('app/SET_GLOBAL_LOADING', true)

    try {
      const response = await asyncGet('/document/trd/exportedList')

      this.list = response.map(item => new ExportedTRDFile(item))
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }

    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    setDate(itemId) {
      let today = new Date()

      if (itemId == 'today') {
        this.file.dates = this.dateToDBFormat(today)
      } else if (itemId == 'yesterday') {
        const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        // this.file.dates = this.dateToDBFormat(yesterday)
        this.file.dates = [this.dateToDBFormat(yesterday), this.dateToDBFormat(yesterday)]
      } else if (itemId == 'last7') {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

        this.file.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(today)]
      } else if (itemId == 'last30') {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)

        this.file.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(today)]
      } else if (itemId == 'month') {
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)

        this.file.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(today)]
      } else if (itemId == 'lastmonth') {
        const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        const lastDay = new Date(today.getFullYear(), today.getMonth(), 0)

        this.file.dates = [this.dateToDBFormat(firstDay), this.dateToDBFormat(lastDay)]
      }
    },
    dateToDBFormat(date) {
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0')
      )
    },
    dateDBFormatToThaiStandardFormat(dateString) {
      const [year, month, date] = dateString.split('-')

      return date + '/' + month + '/' + year
    },
    callCalendarModal() {
      this.file.dates = null
      this.calendarModal = true
    },
    async submitForm() {
      this.sendingData = true
      try {
        await asyncPostAsJson('/document/trd/export', this.file.serailize())

        const response = await asyncGet('/document/trd/exportedList')

        this.list = response.map(item => new ExportedTRDFile(item))
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.sendingData = false
    },
  },
  computed: {
    displayDate() {
      if (!this.file.dates) return 'โปรดเลือกวันที่'

      if (Array.isArray(this.file.dates)) {
        return this.file.dates.map(dateString => this.dateDBFormatToThaiStandardFormat(dateString)).join(' - ')
      }

      return this.dateDBFormatToThaiStandardFormat(this.file.dates)
    },
  },
}
</script>